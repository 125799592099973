import { Form, Input, Row, Col, Select } from "antd";

const GuardianInformation = ({ required = true, formName, formInstance }) => {
  return (
    <Form key={formName} form={formInstance} layout="vertical">
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="firstName"
            label="First Name"
            rules={[{ required }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="lastName"
            label="Last Name"
            rules={[{ required }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="relationship"
            label="Relationship"
            rules={[{ required }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue={null}
            name="isPrimaryContact"
            label="Primary Contact"
            rules={[{ required }]}>
            <Select>
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="phoneNumber"
            label="Phone"
            rules={[{ required }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="mobilePhoneNumber"
            label="Mobile Phone"
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="email"
            label="Email"
            rules={[{ required }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="collegeName"
            label="College Name"
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="occupation"
            label="Occupation"
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="employer"
            label="Employer"
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default GuardianInformation;
