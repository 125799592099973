import { createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

const slice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    userId: null,
    userName: null,
    userAttributes: null,
    userGroups: [],
  },
  reducers: {
    userLoading: (state, action) => {
      state.loading = action.payload;
    },
    getUserSuccess: (state, action) => {
      state.loading = false;
      state.userId = action.payload.user.username;
      state.userName = action.payload.user.username;
      state.userAttributes = action.payload.user.attributes;
      state.userGroups =
        action.payload.user.signInUserSession.accessToken.payload[
          "cognito:groups"
        ];
    },
  },
});
export default slice.reducer;

// Actions
export const { userLoading, getUserSuccess } = slice.actions;

export const getUser = () => async dispatch => {
  try {
    dispatch(userLoading(true));
    const user = await Auth.currentAuthenticatedUser();
    dispatch(getUserSuccess({ user: user }));
  } catch (e) {
    dispatch(userLoading(false));
    alert(e.message);
  }
};
