import { Card, Table } from "antd";
import {useDispatch, useSelector} from "react-redux";
import { useLocation } from "@reach/router";
import { parseQueryString } from "../../shared-components/queryStringUtils";
import { SeasonsSelect } from "../../shared-components/seasonsSelect";
import {
  getPlayerContribution,
  selectPlayerContributionByPlayerIdAndSeasonId,
  selectPlayerContributionDataLoading,
} from "../../redux/playerContribution_slice";
import {useEffect} from "react";
import roundToTwo from "../../utils/roundToTwo";

const PlayerContribution = ({ playerId }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { seasonId } = parseQueryString(location.search);

  const playerContributionLoading = useSelector(selectPlayerContributionDataLoading);

  const playerContributionData = useSelector(
      selectPlayerContributionByPlayerIdAndSeasonId(playerId, seasonId),
  );

  useEffect(() => {
    if (playerId && seasonId && !playerContributionLoading && !playerContributionData) {
      dispatch(getPlayerContribution(playerId, seasonId));
    }
  }, [playerId, seasonId]);

  const transformContributionData = (data) => {
    try {
      return Object.keys(data.player_stats[0])
          .filter(key => data.player_stats[0][key])
          .map(key => {
            return {
              fieldName: key,
              player_total: data.player_stats[0][key],
              team_total: data.team_stats[0][key],
              player_percent: roundToTwo( 100 * data.player_stats[0][key] / data.team_stats[0][key])
            }
          });
    }
    catch (e) {
      return null;
    }
  };

  const data = transformContributionData(playerContributionData);

  const columns = [
    {
      title: "",
      dataIndex: "fieldName",
      // width: 300,
      fixed: true,
    },
    {
      title: "Player Total",
      dataIndex: "player_total",
      // width: 300,
      fixed: true,
    },
    {
      title: "Player Contribution",
      dataIndex: "player_percent",
      // width: 300,
      fixed: true,
    },
    {
      title: "Team Total",
      dataIndex: "team_total",
      // width: 300,
      fixed: true,
    },
  ];
  return (
    <Card style={{ marginBottom: "10px", height: "100%" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SeasonsSelect sport={"fb"} seasonId={seasonId} />
      </div>
      <h1>Player Contribution</h1>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        tableLayout="fixed"
        loading={playerContributionLoading}
        size="small"
      />
    </Card>
  );
};

export default PlayerContribution;
