import { Col, Descriptions, Divider, Row } from "antd";
import moment from "moment";
import camelCaseToTitleCase from "../../../utils/camelCaseToTitleCase";

const isBoolean = val => "boolean" === typeof val;

// if the field isn't listed here, 
// then it's being automatically converted from camelCase to Title Case in parseLabel
const formLabels = {
  // addressInformation
  cityOfBirth: "City Of Birth",
  liveWith: "I live with",
  parentsStatus: "My Parents Are",
  // hobbiesAndSocialMediaInformation
  otherHobbies: "Hobbies outside of football",
  otherSports: "List sports you play other than football",
  otherGuardians:
    "Outside of your parents/guardian, who will be helping you make your decision?",
  favoriteNflTeam: "Favorite NFL Team",
  youtube: "YouTube page",
  // guardian information
  isPrimaryContact: "Primary Contact",
  mobilePhoneNumber: "Mobile Phone",
  // athletic info
  hundredMeterTime: "100 Meter Time",
  fortyYardTime: "40 Yard Time",
  heightFeet: "Height (feet)",
  heightInches: "Height (inches)",
  weight: "Weight (lbs)",
  combinesAndCampsAttended: "Combines/Camps Attended",
  // academic information
  sat: "SAT",
  gpa: "GPA",
  act: "ACT",
  previousHighSchools: "Previous High Schools Attended",
  gradYear: "Graduating Class",
 // school information
  schoolAddressOne: "School Address 1",
  schoolAddressTwo: "School Address 2"
};

const FormPageHeading = ({ label = "" }) => {
  return (
    <Row style={{ paddingBottom: 8 }}>
      <Col span={24}>
        <div style={{ fontWeight: 500, fontSize: 14 }}>{label}</div>
      </Col>
    </Row>
  );
};

const FormSummary = ({ heading = "", formFields, withDivider = true }) => {
  const mapFormFields = formFields => {
    const stringifiedObjectName = Object.keys({ formFields })[0];

    const parseFormFieldValue = value => {
      if (moment.isMoment(value)) {
        return moment(value).format("YYYY");
      } else if (isBoolean(value)) {
        return value ? "Yes" : "No";
      } else if (!value) {
        return "N/A";
      } else {
        return value;
      }
    };

    const parseLabel = formFieldKey => {
      // use an available mapping from the object at the top of the file
      // OR just convert the key name from camel case to title case
      return formLabels[formFieldKey] ?? camelCaseToTitleCase(formFieldKey);
    };
    return Object.keys(formFields).map(formFieldKey => (
      <Descriptions.Item
        label={parseLabel(formFieldKey)}
        key={`confirmation-modal-${stringifiedObjectName}-${formFieldKey}`}>
        {console.log(
          `${stringifiedObjectName}-${formFieldKey}`,
          formFields[formFieldKey],
        )}
        {parseFormFieldValue(formFields[formFieldKey])}
      </Descriptions.Item>
    ));
  };
  return (
    <>
      <FormPageHeading label={heading} />
      <Descriptions bordered layout="vertical">
        {mapFormFields(formFields)}
      </Descriptions>
      {withDivider && <Divider />}
    </>
  );
};

const ConfirmationModal = ({
  addressInformationForm,
  hobbiesAndSocialMediaInformationForm,
  guardianInformationOneForm,
  guardianInformationTwoForm,
  athleticInformationForm,
  academicInformationForm,
  schoolInformationForm,
  schoolCoachInformationForm,
  guidanceCounselorInformationForm,
}) => {
  const getFormValues = () => {
    return {
      addressInformation: addressInformationForm?.getFormValues([]),
      hobbiesAndSocialMediaInformationForm:
        hobbiesAndSocialMediaInformationForm?.getFormValues(),
    };
  };
  console.log("addressInformationForm", addressInformationForm);
  console.log(
    "hobbiesAndSocialMediaInformationForm",
    hobbiesAndSocialMediaInformationForm,
  );
  return (
    <>
      <FormSummary
        heading={"Address Information"}
        formFields={addressInformationForm}
      />
      <FormSummary
        heading={"Hobbies and Social Media Information"}
        formFields={hobbiesAndSocialMediaInformationForm}
      />
      <FormSummary
        heading={"Guardian Information #1"}
        formFields={guardianInformationOneForm}
      />
      <FormSummary
        heading={"Guardian Information #2"}
        formFields={guardianInformationTwoForm}
      />
      <FormSummary
        heading={"Athletic Information"}
        formFields={athleticInformationForm}
      />
      <FormSummary
        heading={"Academic Information"}
        formFields={academicInformationForm}
      />
      <FormSummary
        heading={"School Information"}
        formFields={schoolInformationForm}
      />
      <FormSummary
        heading={"Guidance Counselor Information"}
        formFields={guidanceCounselorInformationForm}
      />
      <FormSummary
        heading={"School Coach Information"}
        formFields={schoolCoachInformationForm}
      />
    </>
  );
};

export default ConfirmationModal;
