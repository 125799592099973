import { Form, Input, Row, Col, Select, DatePicker } from "antd";
import momentGenerateConfig from "antd/node_modules/rc-picker/lib/generate/moment";
const AcademicInformation = ({ formInstance }) => {
  const classes = [
    "High School",
    "2-Year College",
    "4-Year College",
    "Post-High School, Non-College",
  ];

  const MomentDatePicker = DatePicker.generatePicker(momentGenerateConfig);
  return (
    <Form form={formInstance} layout="vertical">
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item initialValue="" name="sat" label={"SAT"}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item initialValue="" name="act" label={"ACT"}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="gpa"
            label={"GPA"}
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item initialValue="" name="classRank" label={"Class Rank"}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            name="gradYear"
            label={"Graduating Class"}
            rules={[{ required: true }]}>
            <MomentDatePicker picker="year" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="intendedMajor"
            label="Intended Major">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        initialValue=""
        name="previousHighSchools"
        label="Previous High Schools attended">
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};

export default AcademicInformation;
