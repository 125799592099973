import { Form, Input, Row, Col, Select } from "antd";
const SchoolCoachInformation = ({ formInstance }) => {
  return (
    <Form form={formInstance} layout="vertical">
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="firstName"
            label="First Name"
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="lastName"
            label="Last Name"
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={24}>
          <Form.Item
            initialValue={""}
            name="email"
            label="Email"
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="title"
            label="Title"
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="phoneNumber"
            label="Phone"
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SchoolCoachInformation;
