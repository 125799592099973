import { API } from "aws-amplify";

export const GET_ROSTER_PLAYERS_STARTED = "GET_ROSTER_PLAYERS_STARTED";
export const GET_ROSTER_PLAYERS_SUCCESS = "GET_ROSTER_PLAYERS_SUCCESS";
export const GET_ROSTER_PLAYERS_FAILURE = "GET_ROSTER_PLAYERS_FAILURE";

export const GET_ROSTER_TEAMS_STARTED = "GET_ROSTER_TEAMS_STARTED";
export const GET_ROSTER_TEAMS_SUCCESS = "GET_ROSTER_TEAMS_SUCCESS";
export const GET_ROSTER_TEAMS_FAILURE = "GET_ROSTER_TEAMS_FAILURE";

export const PUT_ROSTER_PLAYERS_STARTED = "PUT_ROSTER_PLAYERS_STARTED";
export const PUT_ROSTER_PLAYERS_SUCCESS = "PUT_ROSTER_PLAYERS_SUCCESS";
export const PUT_ROSTER_PLAYERS_FAILURE = "PUT_ROSTER_PLAYERS_FAILURE";

export const ADD_ROSTER_PLAYERS_STARTED = "ADD_ROSTER_PLAYERS_STARTED";
export const ADD_ROSTER_PLAYERS_SUCCESS = "ADD_ROSTER_PLAYERS_SUCCESS";
export const ADD_ROSTER_PLAYERS_FAILURE = "ADD_ROSTER_PLAYERS_FAILURE";

export const UPDATE_ROSTER_PLAYERS_BY_POSITION =
  "UPDATE_ROSTER_PLAYERS_BY_POSITION";
export const RESET_ROSTER_PLAYERS_BY_POSITION =
  "RESET_ROSTER_PLAYERS_BY_POSITION";
export const RESET_ROSTER_PLAYERS = "RESET_ROSTER_PLAYERS";

export function getRosterPlayersBySeasonId(seasonId) {
  console.log("getting rosters for season:", seasonId);
  return async dispatch => {
    try {
      dispatch(getRosterPlayersStarted());
      const players = await API.get(
        "coachAPI",
        `rosterplayers?seasonId=${seasonId}`,
      );
      console.log("got Rosters");
      dispatch(getRosterPlayersSuccess(players, null, seasonId));
    } catch (e) {
      console.log("problem getting rosters:", e);
      dispatch(getRosterPlayersFailure(e));
    }
  };
}

export function getRosterPlayersByRosterId(rosterId) {
  console.log("getting roster for id: ", rosterId);
  return async dispatch => {
    try {
      dispatch(getRosterPlayersStarted());
      const players = await API.get("coachAPI", `rosterplayers/${rosterId}`);
      console.log("got Roster for:", rosterId);
      dispatch(getRosterPlayersSuccess(players, rosterId));
    } catch (e) {
      console.log("problem getting roster:", e);
      dispatch(getRosterPlayersFailure(e));
    }
  };
}

const getRosterPlayersSuccess = (
  apiRes = {},
  rosterId = null,
  seasonId = null,
) => ({
  type: GET_ROSTER_PLAYERS_SUCCESS,
  payload: {
    rosterId,
    seasonId,
    rosterPlayers: [...apiRes],
  },
});

const getRosterPlayersStarted = () => ({
  type: GET_ROSTER_PLAYERS_STARTED,
});

const getRosterPlayersFailure = error => ({
  type: GET_ROSTER_PLAYERS_FAILURE,
  payload: {
    error,
  },
});

export function getRosterTeams(seasonId, usePlayerApi) {
  console.log("getting teams for current user");
  return async dispatch => {
    try {
      dispatch(getRosterTeamsStarted());
      const teams = await API.get(
        usePlayerApi ? "playerAPI" : "coachAPI",
        `rosterteams/${seasonId}`,
      );
      console.log("got Teams");
      dispatch(getRosterTeamsSuccess(seasonId, teams));
    } catch (e) {
      console.log("problem getting teams:", e);
      dispatch(getRosterTeamsFailure(e));
    }
  };
}

const getRosterTeamsSuccess = (seasonId, apiRes = {}) => ({
  type: GET_ROSTER_TEAMS_SUCCESS,
  payload: {
    seasonId,
    teams: [...apiRes],
  },
});

const getRosterTeamsStarted = () => ({
  type: GET_ROSTER_TEAMS_STARTED,
});

const getRosterTeamsFailure = error => ({
  type: GET_ROSTER_TEAMS_FAILURE,
  payload: {
    error,
  },
});

export function putRosterPlayers(players, dispatchOnSuccess = null) {
  return async dispatch => {
    try {
      dispatch(putRosterPlayersStarted(players));
      const rosterPlayers = await API.put("coachAPI", `rosterplayers`, {
        body: players,
      });
      if (dispatchOnSuccess) dispatch(dispatchOnSuccess());
      console.log("put rosterplayers for:", players);
      return dispatch(putRosterPlayersSuccess(rosterPlayers));
    } catch (e) {
      console.log("problem putting rosterplayers:", e);
      dispatch(putRosterPlayersFailure(e.message));
    }
  };
}

const putRosterPlayersSuccess = resp => ({
  type: PUT_ROSTER_PLAYERS_SUCCESS,
  payload: { ...resp },
});

const putRosterPlayersStarted = players => ({
  type: PUT_ROSTER_PLAYERS_STARTED,
  payload: { rosterPlayers: players },
});

const putRosterPlayersFailure = error => ({
  type: PUT_ROSTER_PLAYERS_FAILURE,
  payload: {
    error,
  },
});

export function addRosterPlayers(
  rosterId,
  rosterPlayers,
  dispatchOnSuccess = null,
) {
  return async dispatch => {
    try {
      dispatch(addRosterPlayersStarted(rosterPlayers));
      const players = await API.post("coachAPI", `rosterplayers/${rosterId}`, {
        body: rosterPlayers,
      });
      if (dispatchOnSuccess) dispatch(dispatchOnSuccess());
      console.log("add rosterplayers for:", rosterPlayers);
      return dispatch(addRosterPlayersSuccess(players));
    } catch (e) {
      console.log("problem adding rosterplayers:", e);
      return dispatch(addRosterPlayersFailure(e.message));
    }
  };
}

const addRosterPlayersSuccess = resp => ({
  type: ADD_ROSTER_PLAYERS_SUCCESS,
  payload: { ...resp },
});

const addRosterPlayersStarted = (rosterId, players) => ({
  type: ADD_ROSTER_PLAYERS_STARTED,
  payload: { rosterId, players },
});

const addRosterPlayersFailure = error => ({
  type: ADD_ROSTER_PLAYERS_FAILURE,
  payload: {
    error,
  },
});

export function createScoutingTeam(teamName, seasonId, sport) {
  return async dispatch => {
    await API.post("coachAPI", "rosterteams", {
      body: { teamName, seasonId, sport },
    });
    dispatch(getRosterTeams(seasonId));
  };
}

export const resetRosterPlayerDataByPosition = () => ({
  type: RESET_ROSTER_PLAYERS_BY_POSITION,
});

export const resetRosterPlayerData = () => ({
  type: RESET_ROSTER_PLAYERS,
});
