import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { STATES } from "../../../../shared-components/categories_fb";
const HobbiesAndSocialMediaInformation = ({ formInstance }) => {
  return (
    <Form form={formInstance} layout="vertical">
      <Form.Item
        initialValue=""
        name="otherHobbies"
        label="Hobbies outside of football">
        <Input />
      </Form.Item>
      <Form.Item
        initialValue=""
        name="otherSports"
        label="List sports you play other than football">
        <Input />
      </Form.Item>
      <Form.Item
        initialValue=""
        name="otherGuardians"
        label="Outside of your parents/guardian, who will be helping you make your decision?">
        <Input />
      </Form.Item>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="favoriteCollegeTeam"
            label="Favorite College Team">
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="favoriteNflTeam"
            label="Favorite NFL Team">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item initialValue="" name="facebookLink" label="Facebook Link">
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item initialValue="" name="twitterLink" label="Twitter Link">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item initialValue="" name="youtube" label="YouTube page">
        <Input />
      </Form.Item>
    </Form>
  );
};

export default HobbiesAndSocialMediaInformation;
