import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMyPlayerIds,
  selectPlayerAboutsByPlayerId,
  selectPlayerLinksByPlayerId,
  selectPlayerProfiledataByPlayerId,
  selectPlayerProfileLoading,
} from "../../redux/selectors/playerProfileSelectors";
import {
  getMyPlayerIds,
  getPlayerAboutsByPlayerId,
  getPlayerLinksByPlayerId,
  getPlayerProfiledataByPlayerId,
  putPlayerAboutsByPlayerId,
  putPlayerContactDetailsByPlayerId,
  putPlayerLinksByPlayerId,
} from "../../redux/actions/playerProfileActions";
import { Button, Form, Input, Card, Row, Col, Table, Layout, Spin } from "antd";
import { getPlayerstatsByPlayerId } from "../../redux/actions/playerstatsActions";
import { selectPlayerstatsByPlayerId } from "../../redux/selectors/playerstatsSelectors";

const PlayerEditProfile = ({ playerId }) => {
  const abouts = useSelector(selectPlayerAboutsByPlayerId(playerId));
  const links = useSelector(selectPlayerLinksByPlayerId(playerId));
  const playerStatsData = useSelector(selectPlayerstatsByPlayerId(playerId));
  const playerStatsDataLoading = useSelector(
    store => store.playerstatsData.loading,
  );

  //   const myPlayerIds = useSelector(selectMyPlayerIds);
  const loading = useSelector(selectPlayerProfileLoading);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    // if (!loading && !(abouts && links)) {
    dispatch(getPlayerAboutsByPlayerId(playerId));
    dispatch(getPlayerLinksByPlayerId(playerId));
    dispatch(getMyPlayerIds());
    // }
  }, [playerId]);

  useEffect(() => {
    if (playerId) {
      dispatch(getPlayerstatsByPlayerId(playerId));
    }
  }, [playerId]);

  useEffect(() => {
    if (playerStatsData && playerStatsData?.player_info[0]) {
      form.setFieldsValue({
        playerName: playerStatsData?.player_info[0]?.playerName,
        email: playerStatsData?.player_info[0]?.email,
        phone: playerStatsData?.player_info[0]?.phone,
      });
    }
  }, [playerStatsData?.player_info, form]);

  useEffect(() => {
    if (abouts && abouts[0]) {
      form.setFieldsValue({ aboutContent: abouts[0].content });
    }
  }, [abouts, form]);

  useEffect(() => {
    if (links && links.length > 0) {
      console.log("links useEffect fired");
      form.setFieldsValue(
        Object.fromEntries(links.map(l => [l.linkType, l.link])),
      );
    }
  }, [links, form]);

  const layout = {
    // labelCol: {
    //   span: ,
    // },
    wrapperCol: {
      span: 12,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 1,
      //span: 16,
    },
  };

  const onFinish = values => {
    dispatch(
      putPlayerContactDetailsByPlayerId(playerId, {
        playerName: values.playerName,
        email: values.email,
        phone: values.phone,
      }),
    );
    dispatch(
      putPlayerAboutsByPlayerId(playerId, { content: values.aboutContent }),
    );
    dispatch(
      putPlayerLinksByPlayerId(playerId, {
        links: [
          { linkType: "link1", link: values.link1 },
          { linkType: "link2", link: values.link2 },
          { linkType: "link3", link: values.link3 },
        ],
      }),
    );
  };

  const onFinishFailed = errorInfo => {};

  const onReset = () => {
    form.setFieldsValue({
      playerName: playerStatsData?.player_info[0]?.playerName,
      email: playerStatsData?.player_info[0]?.email,
      phone: playerStatsData?.player_info[0]?.phone,
    });
    form.setFieldsValue({ aboutContent: abouts[0].content });
    form.setFieldsValue(
      Object.fromEntries(links.map(l => [l.linkType, l.link])),
    );
  };

  const EditProfileForm = (
    <Form
      {...layout}
      disabled={loading || playerStatsDataLoading}
      name="AboutsAndLinks"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}>
      <Form.Item
        label="Name"
        name="playerName"
        rules={[
          {
            required: true,
            max: 256,
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="E-mail"
        name="email"
        rules={[
          {
            required: true,
            max: 100,
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        label={"Phone"}
        name="phone"
        rules={[
          {
            required: true,
            max: 20,
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="About"
        name="aboutContent"
        rules={[
          {
            max: 256,
          },
        ]}>
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item
        label="Hudl Profile Link"
        name="link1"
        rules={[
          {
            max: 256,
          },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Twitter Profile Link"
        name="link2"
        rules={[
          {
            max: 256,
          },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="MaxPreps Profile Link"
        name="link3"
        rules={[
          {
            max: 256,
          },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        <Button onClick={onReset}>Reset</Button>
      </Form.Item>
    </Form>
  );

  return (
    <Layout>
      <Layout.Content style={{ paddingBottom: "48px" }}>
        <h1>Edit Profile</h1>
        {loading || playerStatsDataLoading ? (
          <Spin>{EditProfileForm}</Spin>
        ) : (
          EditProfileForm
        )}
        {/* </Col>
        </Row> */}
      </Layout.Content>
    </Layout>
  );
};

export default PlayerEditProfile;
