import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyPlayerIds } from "../../redux/actions/playerProfileActions";
import { Layout, Spin, Descriptions } from "antd";
import { getPlayerstatsByPlayerId } from "../../redux/actions/playerstatsActions";
import { selectPlayerstatsByPlayerId } from "../../redux/selectors/playerstatsSelectors";
const PlayerSchoolProfile = ({ playerId }) => {
  const playerStatsData = useSelector(selectPlayerstatsByPlayerId(playerId));
  const playerStatsDataLoading = useSelector(
    store => store.playerstatsData.loading,
  );

  //   const myPlayerIds = useSelector(selectMyPlayerIds);
  const dispatch = useDispatch();

  useEffect(() => {
    // if (!loading && !(abouts && links)) {
    dispatch(getMyPlayerIds());
    // }
  }, [playerId]);

  useEffect(() => {
    if (playerId) {
      dispatch(getPlayerstatsByPlayerId(playerId));
    }
  }, [playerId]);

  const styles = {
    descriptionItem: {
      paddingBottom: 24,
    },
  };

  const ViewSchoolProfileForm = (
    <>
      <Descriptions>
        <Descriptions.Item
          label="School Name"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.schoolName}
        </Descriptions.Item>
        <Descriptions.Item
          label="School Mascot"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.mascot}
        </Descriptions.Item>
        <Descriptions.Item
          label="School Address"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.address}
        </Descriptions.Item>
        <Descriptions.Item
          label="School City"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.city}
        </Descriptions.Item>
        <Descriptions.Item
          label="School State"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.state}
        </Descriptions.Item>
        <Descriptions.Item
          label="School Zip"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.zipCode}
        </Descriptions.Item>
        <Descriptions.Item
          label="Conference"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.conference}
        </Descriptions.Item>
        <Descriptions.Item
          label="Division"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.division}
        </Descriptions.Item>
        <Descriptions.Item
          label="Region"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.region}
        </Descriptions.Item>
        <Descriptions.Item
          label="District"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.district}
        </Descriptions.Item>
        <Descriptions.Item
          label="Primary Contact"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.primaryContactName}
        </Descriptions.Item>
        <Descriptions.Item
          label="Primary Phone"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.primaryContactPhone}
        </Descriptions.Item>
        <Descriptions.Item
          label="Primary Email"
          span={24}
          style={styles.descriptionItem}>
          {playerStatsData?.player_info[0]?.primaryContactEmail}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

  return (
    <Layout>
      <Layout.Content style={{ paddingBottom: "48px" }}>
        <h1>Team Info</h1>
        {playerStatsDataLoading ? <Spin /> : ViewSchoolProfileForm}
      </Layout.Content>
    </Layout>
  );
};

export default PlayerSchoolProfile;
