import { Form, Input, Row, Col, Select, InputNumber } from "antd";
const AthleticInformation = ({ formInstance }) => {
  const offensivePositions = [
    "ATH",
    "FB",
    "OL",
    "QB",
    "RB",
    "TE",
    "WR",
    "H",
    "OC",
    "OG",
    "OT",
    "SLOT",
  ];

  const defensivePositions = [
    "ATH",
    "CB",
    "DE",
    "DT",
    "LB",
    "S",
    "BUCK",
    "ILB",
    "OLB",
    "SAF",
    "H",
  ];
  return (
    <Form form={formInstance} layout="vertical">
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item label="Height" required={true}>
            <Form.Item
              noStyle
              name="heightFeet"
              label={"feet"}
              rules={[{ required: true }]}>
              <InputNumber
                style={{ width: "50%" }}
                suffix="ft"
                min={4}
                max={7}
              />
            </Form.Item>
            <Form.Item
              noStyle
              name="heightInches"
              label={"inches"}
              style={{ width: "50%" }}
              rules={[{ required: true }]}>
              <InputNumber
                style={{ width: "50%" }}
                suffix="in"
                min={1}
                max={12}
              />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            name="weight"
            label={"Weight"}
            rules={[{ required: true }]}>
            <InputNumber suffix={"lbs"} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="jerseyNumber"
            label={"Jersey Number"}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="athleticHonors"
            label={"Athletic Honors"}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={7}>
          <Form.Item initialValue="" name="bench" label={"Bench"}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item initialValue="" name="powerClean" label={"Power Clean"}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item initialValue="" name="squat" label={"Squat"}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={7}>
          <Form.Item
            initialValue=""
            name="fortyYardTime"
            label={"40 Yard Time"}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            initialValue=""
            name="hundredMeterTime"
            label={"100 Meter Time"}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            initialValue=""
            name="verticalJump"
            label={"Vertical Jump"}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={7}>
          <Form.Item
            initialValue=""
            name="offensivePosition"
            label={"Offensive Position"}>
            <Select allowClear>
              {offensivePositions.map((position, index) => (
                <Select.Option
                  key={`offensive-position-option-${position}-${index}`}
                  value={position}>
                  {position}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            initialValue=""
            name="defensivePosition"
            label={"Defensive Position"}>
            <Select allowClear>
              {defensivePositions.map((position, index) => (
                <Select.Option
                  key={`defensive-position-option--${position}-${index}`}
                  value={position}>
                  {position}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            initialValue=""
            name="specialTeams"
            label={"Special Teams"}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="yearsPlayed"
            label={"Years of organized football played"}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        initialValue=""
        name="injuriesAndSurgeries"
        label="Injuries/Surgeries (Please list types and years)">
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        initialValue=""
        name="combinesAndCampsAttended"
        label="Combines/Camps Attended (Please list years)">
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};

export default AthleticInformation;
