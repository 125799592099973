import { createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import * as queryString from "query-string";

const slice = createSlice({
  name: "playerContributionData",
  initialState: {
    loading: false,
    updating: false,
    dataByPlayerId: {},
    error: null,
  },
  reducers: {
    playerContributionDataLoading: state => {
      state.loading = true;
      state.error = null;
    },
    getPlayerContributionSuccess: (state, action) => {
      state.loading = false;

      if (state.dataByPlayerId[action.payload.playerId]) {
        state.dataByPlayerId[action.payload.playerId] = {
          ...state.dataByPlayerId[action.payload.playerId],
          [action.payload.seasonId]: action.payload.data,
        };
      }
      else {
        state.dataByPlayerId = {
          ...state.dataByPlayerId,
          [action.payload.playerId]: {[action.payload.seasonId]: action.payload.data},
        };
      }

      state.error = null;
    },
    getPlayerContributionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  playerContributionDataLoading,
  getPlayerContributionSuccess,
  getPlayerContributionFailure,
} = slice.actions;

export const getPlayerContribution =
  (playerId, seasonId) =>
  async dispatch => {
    try {
      dispatch(playerContributionDataLoading());
      console.log("getting player contribution");
      const response = await API.get(
        `playerAPI`,
        `player-contribution/${playerId}/${seasonId}`,
      );
      console.log("got player contribution", response);

      if (response.error) {
        dispatch(getPlayerContributionFailure(response.error));
      } else {
        dispatch(getPlayerContributionSuccess({ playerId, seasonId, data: response }));
      }
    } catch (e) {
      dispatch(getPlayerContributionFailure(e));
    }
  };

export const selectPlayerContributionDataLoading = store =>
  store.playerContributionData.loading;

export const selectPlayerContributionByPlayerIdAndSeasonId =
  (playerId, seasonId) => store => {
    try {
      return store.playerContributionData.dataByPlayerId[playerId][seasonId];
    } catch (e) {
      return null;
    }
  };
