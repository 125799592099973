import { createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import * as queryString from "query-string";

const slice = createSlice({
  name: "teamPerformanceCapabilitiesData",
  initialState: {
    loading: false,
    updating: false,
    dataByRosterId: {},
    error: null,
  },
  reducers: {
    teamPerformanceCapabilitiesDataLoading: state => {
      state.loading = true;
      state.error = null;
    },
    getTeamPerformanceCapabilitiesSuccess: (state, action) => {
      state.loading = false;
      state.dataByRosterId = {
        ...state.dataByRosterId,
        [action.payload.rosterId]: action.payload.data,
      };
      state.error = null;
    },
    getTeamPerformanceCapabilitiesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  teamPerformanceCapabilitiesDataLoading,
  getTeamPerformanceCapabilitiesSuccess,
  getTeamPerformanceCapabilitiesFailure,
} = slice.actions;

export const getTeamPerformanceCapabilitiesByRosterId = (rosterId, apiType = "coach") => async dispatch => {
  try {
    dispatch(teamPerformanceCapabilitiesDataLoading());
    console.log("getting team capabilities");
    const response = await API.get(`${apiType}API`, `team-performance-capabilities/${rosterId}`);
    console.log("got team capabilities", response);

    if (response.error) {
      dispatch(getTeamPerformanceCapabilitiesFailure(response.error));
    } else {
      dispatch(
        getTeamPerformanceCapabilitiesSuccess({ rosterId, data: response }),
      );
    }
  } catch (e) {
    dispatch(getTeamPerformanceCapabilitiesFailure(e));
  }
};

export const selectTeamCapabilitiesDataLoading = store =>
  store.teamPerformanceCapabilitiesData.loading;

export const selectTeamCapabilitiesDataByRosterId = rosterId => store => {
  try {
    return store.teamPerformanceCapabilitiesData.dataByRosterId[rosterId];
  } catch (e) {
    return null;
  }
};
