import React, { useEffect } from "react";
import GeneralInfo from "./playerComponents/GeneralInfo";
import NongameStats from "./playerComponents/NongameStats";
import GamestatsTable from "./playerComponents/GamestatsTable";
import { Layout, Tabs } from "antd";
import statGroupings from "../shared-components/categories_fb";
import { useDispatch, useSelector } from "react-redux";
import { selectPlayerstatsByPlayerId } from "../redux/selectors/playerstatsSelectors";
import { getPlayerstatsByPlayerId } from "../redux/actions/playerstatsActions";
import RecruiterNotes from "./playerComponents/RecruiterNotes";
import Messages from "./playerComponents/Messages";
import * as queryString from "query-string";
import { useLocation, useNavigate } from "@reach/router";
import AboutsAndLinks from "./playerComponents/AboutsAndLinks";
import RecruiterPlayerPipelineHistory from "./playerComponents/RecruiterPlayerPipelineHistory";
import { getUser } from "../redux/user_slice";
import { updateReferral } from "../redux/referral_slice";
import PlayerContribution from "./playerComponents/PlayerContribution";

const PlayerScreen = ({ playerId }) => {
  const data = useSelector(selectPlayerstatsByPlayerId(playerId));
  const loading = useSelector(store => store.playerstatsData.loading);
  const userData = useSelector(state => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  const { tab } = queryString.parse(location.search);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userData.userId) {
      dispatch(getUser());
    }

    if (!loading && !data) {
      dispatch(getPlayerstatsByPlayerId(playerId));
      dispatch(updateReferral(playerId));
    }
  });

  const onChangeTab = tab => {
    const queryParams = queryString.parse(location.search);
    navigate(
      location.pathname + "?" + queryString.stringify({ ...queryParams, tab }),
      { replace: true },
    );
  };

  let tabs = !data
    ? null
    : Object.keys(statGroupings)
        .filter(
          sg =>
            data &&
            data.season_stats_fb.filter(
              r =>
                statGroupings[sg].keyStats.some(s =>
                  parseInt(r[statGroupings[sg].statPrefix + s]),
                ) > 0,
            ).length > 0,
        )
        .map(sg => (
          <Tabs.TabPane
            key={sg}
            value={sg}
            tab={statGroupings[sg].name + " Stats"}>
            <GamestatsTable
              playerId={playerId}
              statGrouping={sg}
              sport={"fb"}
            />
          </Tabs.TabPane>
        ));

    if (
        tabs &&
        userData &&
        userData.userGroups &&
        userData.userGroups.includes("admins")
    ) {
        tabs = tabs
            .concat(
                <Tabs.TabPane
                    key={"player-contribution"}
                    value={"player-contribution"}
                    tab={"Player Contribution"}>
                    <PlayerContribution playerId={playerId}/>
                </Tabs.TabPane>,
            );
    }

    if (
        tabs && userData
    ) {
        tabs = tabs
            .concat(
                <Tabs.TabPane
                    key={"nongame-stats"}
                    value={"nongame-stats"}
                    tab={"Non-game Stats"}>
                    <NongameStats playerId={playerId}/>
                </Tabs.TabPane>,
            )
            .concat(
                <Tabs.TabPane key={"abouts"} value={"abouts"} tab={"About & Links"}>
                    <AboutsAndLinks playerId={playerId}/>
                </Tabs.TabPane>,
            );
    }

  if (
    tabs &&
    userData &&
    userData.userGroups &&
    userData.userGroups.includes("recruiters")
  ) {
    tabs = tabs
      .concat(
        <Tabs.TabPane
          key={"recruiter-notes"}
          value={"recruiter-notes"}
          tab={"Recruiter Notes"}>
          <RecruiterNotes playerId={playerId} />
        </Tabs.TabPane>,
      )
      .concat(
        <Tabs.TabPane
          key={"recruiter-pipeline-history"}
          value={"recruiter-pipeline-history"}
          tab={"Player Pipeline History"}>
          <RecruiterPlayerPipelineHistory playerId={playerId} />
        </Tabs.TabPane>,
      )
      .concat(
        <Tabs.TabPane key={"messages"} value={"messages"} tab={"Messages"}>
          <Messages playerId={playerId} />
        </Tabs.TabPane>,
      );
  }

  return (
    <Layout>
      <Layout.Content style={{ marginBottom: 50 }}>
        <GeneralInfo playerId={playerId} />
        <Tabs
          activeKey={tab ? tab : tabs ? tabs[0]?.key : null}
          onChange={onChangeTab}
          size={"large"}
          style={{ background: "white" }}>
          {tabs}
        </Tabs>
      </Layout.Content>
    </Layout>
  );
};

export default PlayerScreen;
