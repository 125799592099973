import { Form, Input, Row, Col, Select } from "antd";
import { STATES } from "../../../shared-components/categories_fb";

const SchoolInformation = ({ formInstance }) => {
  return (
    <Form form={formInstance} layout="vertical">
      <Row justify={"space-between"} align={"center"}>
        <Col span={24}>
          <Form.Item
            initialValue=""
            name="schoolName"
            label={"School Name"}
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="schoolAddressOne"
            label={"School Address 1"}
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="schoolAddressTwo"
            label={"School Address 2"}
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="schoolCity"
            label={"School City"}
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="schoolState"
            label="School State"
            rules={[{ required: false }]}>
            <Select>
              {STATES.map(state => (
                <Select.Option value={`${state.st}`}>{state.st}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="schoolZip"
            label={"School Zip"}
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="schoolCountry"
            label="School Country"
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="phone"
            label={"Phone"}
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="fax"
            label="Fax"
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="mascot"
            label={"Mascot"}
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="colors"
            label="Colors"
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={24}>
          <Form.Item
            initialValue=""
            name="website"
            label={"Website"}
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SchoolInformation;
