import { Form, Input, Row, Col, Select } from "antd";
const GuidanceCounselorInformation = ({ formInstance }) => {
  return (
    <Form form={formInstance} layout="vertical">
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="guidanceCounselorFirstName"
            label={"Guidance Counselor's First Name"}
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="guidanceCounselorLastName"
            label={"Guidance Counselor's Last Name"}
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={24}>
          <Form.Item
            initialValue=""
            name="guidanceCounselorEmail"
            label={"Guidance Counselor's Email"}
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="guidanceCounselorPhone"
            label={"Guidance Counselor's Phone"}
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue=""
            name="guidanceCounselorFax"
            label={"Guidance Counselor's Fax"}
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default GuidanceCounselorInformation;
