import React from "react";
import image from "../../resources/logo_white_background.jpg";

const Home = props => (
  <div className={"home"}>
    <div
      className={""}
      style={{
        width: "550px",
        height: "300px",
      }}
    >
      <img
        src={image}
        alt={"logo"}
        style={{
          width: "550px",
          height: "300px",
        }}
      />
    </div>
    <p />
    <h1>Welcome to Playmaker</h1>

    <p>
      We are excited to have you here, and are excited to show you all Playmaker
      has to offer.
    </p>

    <h2>Where to Start:</h2>

    <p>
      If you are starting fresh on Playmaker, the first thing to do is to set up
      your Team Rosters and set up your Team Schedules on their correlating
      tabs. Filling out these two will make all following processes faster,
      however if you only want to utilize our breakdown features, you can skip
      adding a Roster. If you have a full roster you want to upload, please
      contact us and we will get your starting roster uploaded for you for your
      teams.
    </p>

    <p>
      Once you have entered the basics, you are more easily able to review your
      dashboard and begin interacting with more of the platform. You will see at
      the top of your screen, each team and an “Inactive” team and a season
      navigator in the top right of the screen. You can use these to navigate
      between teams and seasons, and review older data that may be populated
      from MaxPreps or other public Forums.
    </p>

    <p>
      For more assistance on how to start these tasks, please reach out to your
      account representative or email us at{" "}
      <a href="mailto:help@playmakerrecruiting.com">
        help@playmakerrecruiting.com
      </a>
      .
    </p>

    <p>We are looking forward to helping your program succeed.</p>
  </div>
);

export default Home;
