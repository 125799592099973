import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { STATES } from "../../../../shared-components/categories_fb";
const AddressInformation = ({ formInstance }) => {
  return (
    <Form form={formInstance} layout="vertical">
      {/* <Form.Item name="address" label="Address" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item name="city" label="City" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item name="state" label="State" rules={[{ required: true }]}>
            <Select>
              {STATES.map(state => (
                <Select.Option value={`${state.st}`}>{state.st}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item name="zipCode" label="Zip" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item
            name="phoneNumber"
            label="Phone"
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            name="altPhoneNumber"
            label="Mobile/Alt Phone"
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Form.Item name="gender" label="Gender">
            <Select allowClear>
              <Select.Option value={"M"}>M</Select.Option>
              <Select.Option value={"F"}>F</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row> */}
      <Row justify={"space-between"} align={"center"}>
        {/* <Col span={11}>
          <Form.Item
            name="dateOfBirth"
            label="Date Of Birth"
            rules={[{ required: true }]}>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col> */}
        <Col span={24}>
          <Form.Item initialValue={""} name="cityOfBirth" label="City Of Birth">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} align={"center"}>
        <Col span={11}>
          <Form.Item initialValue={""} name="liveWith" label="I live with">
            <Select allowClear>
              <Select.Option value={"Mother"}>Mother</Select.Option>
              <Select.Option value={"Father"}>Father</Select.Option>
              <Select.Option value={"Both Parents"}>Both Parents</Select.Option>
              <Select.Option value={"Other"}>Other</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            initialValue={""}
            name="parentsStatus"
            label="My Parents are">
            <Select allowClear>
              <Select.Option value={"Married"}>Married</Select.Option>
              <Select.Option value={"Divorced"}>Divorced</Select.Option>
              <Select.Option value={"Other"}>Other</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AddressInformation;
